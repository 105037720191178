<template>
  <div class="page-title">
    <div class="page-title__title">
      <h1 class="fs-32">{{ $t(title) }}</h1>
      <p class="fs-18">{{ $t(subtitle) }}</p>
    </div>
    <div class="page-title__actions">
      <slot />
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    }
  },
  methods: {}
}
</script>
  <style lang="scss" scoped>
@import "./index.scss";
</style>
  