export const getProfileDataRequest = () => ({
    method: "get",
    url: 'profile'
  })

  export const updateProfileDataRequest = (data) => ({
    method: "post",
    url: 'profile/update',
    config: {
      data
    }
  })