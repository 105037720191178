<template>
  <div class="dashboard">
    <DefaultLayout>
      <PageTitle title="SETTING.PROFILE_SETING" subtitle="SETTING.SUBTITLE"> </PageTitle>
      <div class="tabs">
        <div
          class="info"
          :class="tabType == 'account-info' && 'active'"
          @click="setTabType('account-info')"
        >
          {{ $t("SETTING.ACCOUNT_INFO") }}
        </div>
        <div
          class="password"
          :class="tabType == 'change-password' && 'active'"
          @click="setTabType('change-password')"
        >
          {{ $t("SETTING.CHANGE_PASSWORD") }}
        </div>
      </div>
      <div v-if="tabType == 'account-info'" class="content-container">
        <h3 class="form-title">{{ $t("SETTING.ACCOUNT_INFO") }}</h3>
        <FormValidation @handleSubmit="onSubmitFirst">
          <b-form>
            <div class="info-form">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <TextField
                          v-model="formValues.name"
                          :label="$t('SETTING.NAME')"
                          :name="$t('SETTING.NAME')"
                          :placeholder="$t('SETTING.NAME')"
                          rules="required"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col class="mt-2">
                      <b-form-group>
                        <TextField
                          v-model="formValues.email"
                          label=" "
                          :name="$t('SETTING.EMAIL')"
                          :placeholder="$t('SETTING.EMAIL')"
                          rules="required"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <TextField
                          v-model="formValues.phone"
                          :label="$t('SETTING.PHONE_NUMBER')"
                          :name="$t('SETTING.PHONE_NUMBER')"
                          placeholder="01000000000"
                          rules="required"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <TextField
                          v-model="formValues.type"
                          :label="$t('SETTING.TYPE')"
                          :name="$t('SETTING.TYPE')"
                          :placeholder="$t('SETTING.JOB_PLACEHOLDER')"
                          rules="required"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <h5 class="image-uploader-label">{{ $t("SETTING.PERSONAL_IMAGE") }}</h5>
                  <ImageUploader :avatar="profile.avatar"/>
                </b-col>
              </b-row>

              <b-form-group>
                <Button
                  type="submit"
                  color="primary"
                  :d-block="true"
                  :loading="loading"
                  custom-class="mt-2 text-uppercase"
                >
                  {{ $t("GLOBAL.SAVE_CHANGES") }}
                </Button>
              </b-form-group>
            </div>
          </b-form>
        </FormValidation>
      </div>
      <div v-else class="content-container">
        <h3 class="form-title">{{ $t("SETTING.CHANGE_PASSWORD") }}</h3>
        <b-row class="mt-4">
          <b-col>
            <FormValidation @handleSubmit="onSubmit">
              <b-form>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="form.oldPassword"
                        rules="required"
                        :type="newPasswordType"
                        :label="$t('GLOBAL.CURRENT_PASSWORD')"
                        :name="$t('GLOBAL.CURRENT_PASSWORD')"
                        :placeholder="$t('GLOBAL.ENTER_CURRENT_PASSWORD')"
                        class="p-relative d-block"
                        ref="password"
                      >
                        <b-icon
                          :icon="newPasswordIcon"
                          @click="hideShowPassword('new')"
                          class="icon-password"
                        />
                      </TextField>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="form.newPassword"
                        rules="required"
                        :type="passwordType"
                        :label="$t('GLOBAL.NEW_PASSWORD')"
                        :name="$t('GLOBAL.NEW_PASSWORD')"
                        :placeholder="$t('GLOBAL.ENTER_NEW_PASSWORD')"
                        class="p-relative d-block"
                        ref="password"
                      >
                        <b-icon
                          :icon="passwordIcon"
                          @click="hideShowPassword"
                          class="icon-password"
                        />
                      </TextField>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group>
                  <Button
                    type="submit"
                    color="primary"
                    :d-block="true"
                    :loading="loading"
                    custom-class="mt-2 text-uppercase"
                  >
                    {{ $t("GLOBAL.CONFIRM_CHANGE_PASSWORD") }}
                  </Button>
                </b-form-group>
              </b-form>
            </FormValidation>
          </b-col>
          <b-col></b-col>
        </b-row>
      </div>
    </DefaultLayout>
  </div>
</template>

<script>
import PageTitle from "@/components/Shared/PageTitle/index.vue"
import { TogglePasswordMixins } from "@/mixins/TogglePasswordMixins"
import {getProfileDataRequest, updateProfileDataRequest} from "@/api/profile.js"
import { mapActions } from "vuex"

export default {
  components: {
    PageTitle
  },
  mixins: [TogglePasswordMixins],
  data() {
    return {
      profile: {},
      loading: false,
      tabType: "account-info",
      formValues: {
        name: "",
        email: "",
        phone: "",
        type: ""
      },
      form: {
        oldPassword: "",
        newPassword: ""
      }
    }
  },
  mounted() {
    this.getProfileData()
  },
  methods: {
    ...mapActions(["ShowToast"]),
    setTabType(tabValue) {
      this.tabType = tabValue
    },
    getProfileData() {
      this.loading = true
      this.ApiService(getProfileDataRequest())
        .then((res) => {
          if (res.data.status != "success") {
            this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
          }
          this.profile = res.data.data
          this.formValues = res.data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSubmitFirst() {
      this.loading = true
      this.ApiService(updateProfileDataRequest(this.formValues))
      .then((res) => {
        if (res.data.status == "success") {
            this.ShowToast({
              title: this.$t("GLOBAL.PROFILE_UPDATED_SUCCESS"),
              type: "success",
              message: res.data.message || this.$t("GLOBAL.PROFILE_UPDATED_SUCCESS")
            })
            return res
        }else{
          this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
        }
      })
      .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
